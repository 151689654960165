// 自分宛てのメッセージ一覧を取得

import api from './api'

const path = '/messages'

export default (token = null) => {
  return api({
    url: path,
    auth: true,
    token,
  })
}
