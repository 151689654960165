// メッセージの詳細の取得

import api from './api'

const path = '/messages/{id}'

export default (messageId, token = null) => {
  const url = path.replace('{id}', messageId)
  return api({
    url,
    auth: true,
    token,
  })
}
