// メッセージの詳細ダイアログ

<template lang="pug">
el-dialog.message-detail-dialog(
  title='メッセージの詳細',
  width='80%',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article(v-if='detail')
    h1 {{ detail.subject }}
    .datetime {{ detail.sentAt | jpDateTime }}
    pre {{ detail.body }}
    tracking-image(:tracking-id='detail.trackingId')
</template>

<script>
import getMessageApi from '@/api/get-message'

import TrackingImage from '@/components/tracking-image'

export default {
  name: 'MessageDetailDialog',

  components: {
    TrackingImage,
  },

  data() {
    return {
      detail: null,
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // メッセージのID
    messageId: {
      type: Number,
    },
    // 無理やり感があるが、JWTトークンのオーバーライド
    token: {
      type: String,
      default: null,
    },
  },

  methods: {
    async open() {
      const response = await getMessageApi(this.messageId, this.token)
      if (!response.ok) return
      this.detail = response.payload
    },
  },
}
</script>

<style lang="sass">
.message-detail-dialog
  .el-dialog__body
    padding-top: 0
</style>

<style lang="sass" scoped>
article
  h1
    font-size: 1.2rem
    border-bottom: 1px solid $color-1
  .datetime
    font-size: 0.9rem
    font-weight: bold
    color: darken($color-1, 20%)
    margin-top: 1rem
  pre
    margin-top: 1rem
    font-size: 1rem
    white-space: pre-wrap
</style>
