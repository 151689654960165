// 既読判定のためのトラッキング画像

<template lang="pug">
img.tracking-image(:src='url')
</template>

<script>
const apiBase = process.env.VUE_APP_API_BASE_URL

export default {
  name: 'TrackingImage',

  props: {
    trackingId: {
      type: String,
      required: true,
    },
  },

  computed: {
    url() {
      return `${apiBase}/pixel?id=${this.trackingId}`
    },
  },
}
</script>

<style lang="sass" scoped>
.tracking-image
  width: 1px
  height: 1px
</style>
