// メッセージ受信箱

<template lang="pug">
el-card.member-messages

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  template(v-if='messages.length > 0')
    p 閲覧したいメッセージをクリックしてください。

    ul
      li(v-for='item in messages', :class='{ unread: !item.isRead }')
        a(@click='click(item.id)')
          span.datetime {{ item.sentAt | jpDateTime }}
          span.title {{ item.title }}
          el-tag.tag(v-if='!item.isRead', size='small', type='danger') 未読

  p(v-else) 現在メッセージはありません。

  message-detail-dialog(:visible.sync='dialogVisible', :message-id='messageId')
</template>

<script>
import getMyMessageListApi from '@/api/get-my-message-list'

import MessageDetailDialog from '@/dialogs/message-detail-dialog'

export default {
  name: 'MemberMessages',

  components: {
    MessageDetailDialog,
  },

  data() {
    return {
      messages: () => [],
      dialogVisible: false,
      messageId: 0,
    }
  },

  async created() {
    const response = await getMyMessageListApi()
    if (!response.ok) return
    // 'isRead' プロパティを変更するので、コピーしておく
    this.messages = response.payload.items.map((item) => Object.assign({}, item))
  },

  methods: {
    click(id) {
      // this.messageの該当メッセージを既読扱いとすることで、
      // 見た目上既読とする(この時点ではDBには反映されていない)
      const target = this.messages.find((item) => item.id === id)
      if (target) target.isRead = true
      this.messageId = id
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="sass" scoped>
.member-messages
  padding-bottom: 3rem

  ul
    margin-top: 1rem
    list-style-type: none
    border-bottom: 1px solid $color-1
    li
      border-top: 1px solid $color-1
      a
        display: block
        padding: 0.5rem
        color: $grey-dark
        overflow: hidden
        cursor: pointer
        text-decoration: none
        background: $white-ter
        .title
          margin-left: 1.5rem
        .tag
          float: right
      &.unread
        a
          background: $white
          .datetime, .title
            font-weight: bold
      a:hover
        background: lighten($color-1, 40%)
</style>
